import * as React from "react"
import { Link } from "gatsby"
import { Header, Masthead, TransitionMask } from '@components/organisms'


// markup
const NotFoundPage = () => {
  return (
    <>
      <main>
        <title>Not found</title>
      </main>
      <TransitionMask />
    </>
  )
}

export default NotFoundPage
